import { useQuery } from '@redwoodjs/web'
import { elasticCurrency } from 'src/helpers/formatting'

const GET_LATEST_QUOTE = gql`
  query GetLatestQuote($id: Int!) {
    holding(id: $id) {
      token {
        latestQuote {
          id
          price
        }
      }
    }
  }
`

const PriceAlertLatestPrice = ({ holdingId }) => {
  const { data } = useQuery(GET_LATEST_QUOTE, {
    variables: { id: holdingId },
  })

  if (data) {
    return (
      <div className="text-sm text-gray-400 dark:text-gray-500">
        <span>Current Price: </span>
        {elasticCurrency(data.holding.token.latestQuote.price)}
      </div>
    )
  } else {
    return ''
  }
}

export default PriceAlertLatestPrice
