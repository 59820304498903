import { Router, Route, Private, Set } from '@redwoodjs/router'
import AppLayout from 'src/layouts/AppLayout'
import PriceAlertsPage from 'src/pages/PriceAlert/PriceAlertsPage'
import NewPriceAlertPage from 'src/pages/PriceAlert/NewPriceAlertPage'
import EditPriceAlertPage from 'src/pages/PriceAlert/EditPriceAlertPage'

const Routes = () => {
  return (
    <Router>
      <Private unauthenticated="login">
        <Set wrap={AppLayout}>
          <Route path="/dashboard" page={DashboardPage} name="dashboard" />

          <Route path="/addresses/new" page={NewAddressPage} name="newAddress" />
          <Route path="/addresses" page={AddressesPage} name="addresses" />

          <Route path="/price-alerts/{id:Int}/edit" page={EditPriceAlertPage} name="editPriceAlert" />
          <Route path="/price-alerts/new" page={NewPriceAlertPage} name="newPriceAlert" />
          <Route path="/price-alerts" page={PriceAlertsPage} name="priceAlerts" />

          <Route path="/settings" page={SettingsPage} name="settings" />

          <Route path="/holding/{id:Int}/edit" page={EditHoldingPage} name="editHolding" />
          <Route path="/holding/{id:Int}" page={HoldingPage} name="holding" />
          <Route path="/holding/new" page={NewHoldingPage} name="newHolding" />

          <Route path="/quotes/new/{tokenId:Int}" page={NewQuotePage} name="newQuote" />
          <Route path="/quotes/{id:Int}/edit" page={EditQuotePage} name="editQuote" />
        </Set>
      </Private>

      <Route path="/login" page={LoginPage} name="login" />
      <Route path="/signup" page={SignupPage} name="signup" />
      <Route path="/" page={HomePage} name="home" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
