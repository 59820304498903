const TokenLogo = ({ token, parent, className }) => {
  const shouldIncludeParent = parent?.image && parent.image !== token.image

  return (
    <div className="relative">
      {shouldIncludeParent && (
        <img
          src={parent.image}
          alt={parent.name}
          title={parent.name}
          className="absolute bottom-0 right-0 -mb-1 -mr-1 w-5 rounded-full border border-gray-100 dark:border-gray-700"
        />
      )}
      {token.image ? (
        <img
          src={token.image}
          alt={token.name}
          title={token.name}
          className={className}
        />
      ) : (
        <div className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full">
          <span
            style={{ marginLeft: '1px' }}
            className="material-icons md-24 text-gray-300 cursor-help"
            title="No logo"
          >
            camera
          </span>
        </div>
      )}
    </div>
  )
}

export default TokenLogo
