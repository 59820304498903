import EditPriceAlertCell from 'src/components/PriceAlert/EditPriceAlertCell'
import { setPageInfo } from 'src/lib/state'

const EditPriceAlertPage = ({ id }) => {
  setPageInfo({
    title: 'Edit Price Alert',
    actions: [],
    description: null,
  })

  return <EditPriceAlertCell id={id} />
}

export default EditPriceAlertPage
