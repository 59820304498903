import { useQuery } from '@redwoodjs/web'
import { elasticCurrency } from 'src/helpers/formatting'

const GET_LATEST_PORTFOLIO_TOTAL = gql`
  query GetLatestPortfolioTotal {
    latestPortfolioTotal {
      total
    }
  }
`

const PriceAlertLatestPortfolioPrice = () => {
  const { data } = useQuery(GET_LATEST_PORTFOLIO_TOTAL)

  if (data) {
    return (
      <div className="text-sm text-gray-400 dark:text-gray-500">
        <span>Current Total: </span>
        {elasticCurrency(data.latestPortfolioTotal.total)}
      </div>
    )
  } else {
    return ''
  }
}

export default PriceAlertLatestPortfolioPrice
