import { Link, routes } from '@redwoodjs/router'
const { elasticCurrency, timeFormat } = require('src/helpers/formatting')
import { Switch } from '@headlessui/react'
import { useMutation } from '@redwoodjs/web'
import toast from 'react-hot-toast'

import GenericLoading from 'src/components/GenericLoading'
import TokenLogo from 'src/components/TokenLogo'
import PriceAlertLatestPrice from 'src/components/PriceAlert/PriceAlertLatestPrice'
import PriceAlertLatestPortfolioPrice from 'src/components/PriceAlert/PriceAlertLatestPortfolioPrice'

export const QUERY = gql`
  query PriceAlertsQuery {
    priceAlerts {
      id
      price
      method
      enabled
      sentAt
      createdAt
      holding {
        id
        token {
          name
          symbol
          image
          latestQuote {
            price
          }
        }
      }
    }
  }
`

const DELETE_PRICE_ALERT = gql`
  mutation DeletePriceAlertMutation($id: Int!) {
    deletePriceAlert(id: $id) {
      id
    }
  }
`

export const TOGGLE_PRICE_ALERT_ENABLED = gql`
  mutation TogglePriceAlertEnabled($id: Int!, $input: UpdatePriceAlertInput!) {
    updatePriceAlert(id: $id, input: $input) {
      id
      enabled
    }
  }
`

export const beforeQuery = (props) => {
  return {
    variables: props,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    pollInterval: 1 * 60 * 1000, // 1 minute
  }
}

export const Loading = () => <GenericLoading />

export const Empty = () => (
  <div className="my-32 mx-auto text-center text-sm text-gray-400">
    <p className="text-xl">No price alerts yet!</p>
    <p className="mt-4">
      Price alerts can notify you when a coin/token or your porfolio total moves
      above or below a set amount.{' '}
      <Link to={routes.newPriceAlert()} className="underline text-blue-600">
        Create one?
      </Link>
    </p>
  </div>
)

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success = ({ priceAlerts }) => {
  const [updatePriceAlert] = useMutation(TOGGLE_PRICE_ALERT_ENABLED)
  const [deletePriceAlert] = useMutation(DELETE_PRICE_ALERT, {
    onCompleted: () => {
      toast.success('Price alert deleted')
    },
  })

  const toggleEnabled = (priceAlert) => {
    updatePriceAlert({
      variables: { id: priceAlert.id, input: { enabled: !priceAlert.enabled } },
    })
  }

  const onDelete = (priceAlert) => {
    if (
      confirm(
        `Are you sure you want to delete the price alert for ${
          priceAlert.holding
            ? priceAlert.holding.token.name
            : 'your portfolio total'
        }?`
      )
    ) {
      deletePriceAlert({
        variables: { id: priceAlert.id },
        refetchQueries: [{ query: QUERY }],
      })
    }
  }

  return (
    <table className="w-full text-gray-800 dark:text-gray-300">
      <thead className="text-gray-300 text-sm">
        <tr>
          <th className="py-2 font-medium text-left">Token</th>
          <th className="py-2 font-medium text-left">Alert Price</th>
          <th className="py-2 font-medium text-right">Created</th>
          <th className="py-2 font-medium text-right">Last Sent</th>
          <th className="py-2 pl-4 font-medium text-center">Method</th>
          <th className="py-2 font-medium text-right">Enabled</th>
          <th className="py-2 font-medium text-right">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {priceAlerts.map((priceAlert) => {
          return (
            <tr
              key={priceAlert.id}
              className="border-t dark:border-gray-700 dark:text-gray-200"
            >
              <td className="py-4 flex items-center">
                {priceAlert.holding ? (
                  <>
                    <TokenLogo
                      token={priceAlert.holding.token}
                      parent={priceAlert.holding.token.network}
                      className="w-10"
                    />
                    <div className="ml-3">
                      <span className="font-semibold leading-none">
                        {priceAlert.holding.token.name}
                      </span>
                      <span className="block text-gray-500 leading-none">
                        {priceAlert.holding.token.symbol}
                      </span>
                    </div>
                  </>
                ) : (
                  <span className="font-semibold mt-2">Portfolio Total</span>
                )}
              </td>
              <td className="py-4 text-left">
                <span className="text-lg">
                  {elasticCurrency(priceAlert.price)}
                </span>
                <span className="block text-sm text-gray-400 dark:text-gray-600">
                  {priceAlert.holding ? (
                    <PriceAlertLatestPrice holdingId={priceAlert.holding.id} />
                  ) : (
                    <PriceAlertLatestPortfolioPrice />
                  )}
                </span>
              </td>
              <td className="text-right text-sm text-gray-500">
                <span>{timeFormat(priceAlert.createdAt, 'PPP')}</span>
                <span className="block">
                  {timeFormat(priceAlert.createdAt, 'pp')}
                </span>
              </td>
              <td className="text-right text-sm text-gray-500">
                {priceAlert.sentAt ? (
                  <>
                    <span>{timeFormat(priceAlert.sentAt, 'PPP')}</span>
                    <span className="block">
                      {timeFormat(priceAlert.sentAt, 'pp')}
                    </span>
                  </>
                ) : (
                  'Never'
                )}
              </td>
              <td className="py-4 pl-4 text-center">
                <span
                  className="material-icons md-26 text-gray-400 dark:text-gray-500 cursor-help"
                  title={priceAlert.method === 'email' ? 'Email' : 'SMS'}
                >
                  {priceAlert.method === 'email'
                    ? 'mail_outline'
                    : 'phone_iphone'}
                </span>
              </td>
              <td className="py-4 text-right">
                <Switch
                  checked={priceAlert.enabled}
                  onChange={() => toggleEnabled(priceAlert)}
                  style={{ height: '28px', width: '56px' }}
                  className={`${
                    priceAlert.enabled
                      ? 'bg-green-500'
                      : 'bg-gray-300 dark:bg-gray-700'
                  }
          relative inline-flex flex-shrink-0 ml-2 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    style={{ height: '24px', width: '24px' }}
                    className={`${
                      priceAlert.enabled ? 'translate-x-7' : 'translate-x-0'
                    }
            pointer-events-none inline-block rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
              </td>
              <td>
                <ul className="flex items-center justify-end">
                  <li>
                    <Link
                      to={routes.editPriceAlert({ id: priceAlert.id })}
                      className="flex bg-blue-500 rounded p-2"
                      title="Refresh address holdings"
                    >
                      <span className="material-icons md-18 text-white">
                        edit
                      </span>
                    </Link>
                  </li>
                  <li className="ml-2">
                    <button
                      type="button"
                      onClick={() => onDelete(priceAlert)}
                      className="flex bg-red-500 rounded p-2"
                      title="Delete address and holdings"
                    >
                      <span className="material-icons md-18 text-white">
                        delete_forever
                      </span>
                    </button>
                  </li>
                </ul>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
