import { Link, navigate, routes } from '@redwoodjs/router'
import { Form, Label, TextField, FieldError, Submit } from '@redwoodjs/forms'
import { useRef, useEffect } from 'react'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import PriceAlertLatestPrice from 'src/components/PriceAlert/PriceAlertLatestPrice'
import PriceAlertLatestPortfolioPrice from 'src/components/PriceAlert/PriceAlertLatestPortfolioPrice'
import { elasticCurrency } from 'src/helpers/formatting'

export const QUERY = gql`
  query FindEditPriceAlertQuery($id: Int!) {
    priceAlert: priceAlert(id: $id) {
      id
      price
      enabled
      holding {
        id
        token {
          name
          symbol
        }
      }
    }
  }
`

export const UPDATE_PRICE_ALERT_MUTATION = gql`
  mutation updatePriceAlert($id: Int!, $input: UpdatePriceAlertInput!) {
    updatePriceAlert(id: $id, input: $input) {
      id
      price
      enabled
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success = ({ priceAlert }) => {
  const priceRef = useRef()
  const [updatePriceAlert, { loading }] = useMutation(
    UPDATE_PRICE_ALERT_MUTATION,
    {
      onCompleted: () => {
        navigate(routes.priceAlerts())
        toast.success('Updated price alert!')
      },
    }
  )

  useEffect(() => {
    priceRef.current.focus()
  }, [])

  const onSubmit = (data) => {
    updatePriceAlert({
      variables: {
        id: priceAlert.id,
        input: { price: parseFloat(data.price) },
      },
    })
  }

  return (
    <div className="md:flex items-start">
      <Form onSubmit={onSubmit} className="form md:-mt-8 w-full md:w-2/3">
        {priceAlert.holding && (
          <>
            <Label name="name">Token/Coin</Label>
            <TextField
              name="name"
              disabled={true}
              defaultValue={`${priceAlert.holding.token.name} (${priceAlert.holding.token.symbol})`}
            />
          </>
        )}

        <Label name="quantity">Alert Price</Label>
        <TextField
          name="price"
          placeholder="123.45"
          ref={priceRef}
          defaultValue={elasticCurrency(priceAlert.price).replace(/[$,]/g, '')}
          validation={{ required: 'Price is required' }}
        />
        <FieldError name="price" className="block mt-1 text-sm text-red-500" />

        <div className="mt-2 ml-1">
          {priceAlert.holding ? (
            <PriceAlertLatestPrice holdingId={priceAlert.holding.id} />
          ) : (
            <PriceAlertLatestPortfolioPrice />
          )}
        </div>

        <div className="flex justify-between items-center mt-8">
          <Submit className="button-submit" disabled={loading}>
            Update Price Alert
          </Submit>
          <Link to={routes.priceAlerts()} className="button-cancel">
            Cancel
          </Link>
        </div>
      </Form>
      <aside className="w-full md:w-1/3 mt-8">
        <div className="-mt-2 md:ml-12 text-sm leading-6 dark:text-gray-500">
          <p>
            Price alerts will notify you when the price of a given token crosses
            the threshold you set as the{' '}
            <span className="font-semibold dark:text-gray-400">
              Alert Price
            </span>
            . You'll be notified whether the price <em>increases</em> to your
            alert price, or <em>decreases</em> to it.
          </p>
          <p className="mt-4">
            To prevent thrashing, a price alert is disabled after sending.
          </p>
        </div>
      </aside>
    </div>
  )
}
