import { Link, navigate, routes } from '@redwoodjs/router'
import { Switch } from '@headlessui/react'
import { useMutation } from '@redwoodjs/web'
import { useState } from 'react'
import { Form, Label, TextField, FieldError, Submit } from '@redwoodjs/forms'
import { setPageInfo } from 'src/lib/state'
import toast from 'react-hot-toast'

import TokenSearch from 'src/components/TokenSearch'
import PriceAlertLatestPrice from 'src/components/PriceAlert/PriceAlertLatestPrice'
import PriceAlertLatestPortfolioPrice from 'src/components/PriceAlert/PriceAlertLatestPortfolioPrice'

const CREATE_PRICE_ALERT = gql`
  mutation CreatePriceAlert($input: CreatePriceAlertInput!) {
    createPriceAlert(input: $input) {
      id
    }
  }
`

const NewPriceAlertPage = () => {
  setPageInfo({
    title: 'New Price Alert',
    actions: [],
  })

  const [holdingId, setHoldingId] = useState(null)
  const [_tokenName, setTokenName] = useState('')
  const [enabled, setEnabled] = useState(false)

  const [createPriceAlert, { loading }] = useMutation(CREATE_PRICE_ALERT, {
    onCompleted: async () => {
      toast.success('Price alert created!')
      navigate(routes.priceAlerts())
    },
  })

  const onSubmit = (data) => {
    if ((enabled && holdingId) || !enabled) {
      const input = {
        holdingId,
        price: parseFloat(data.price),
        method: 'email',
      }
      createPriceAlert({
        variables: { input },
      })
    } else {
      alert('Which token do you want to create the alert for?')
    }
  }

  const onChangeEnabled = () => {
    setHoldingId(null)
    setEnabled(!enabled)
  }

  return (
    <div className="md:flex items-start">
      <Form onSubmit={onSubmit} className="form md:-mt-8 w-full md:w-2/3">
        <div className="flex items-center justify-center mt-8 text-gray-500">
          <span className="mr-2">Portfolio Total</span>
          <Switch
            checked={enabled}
            onChange={onChangeEnabled}
            style={{ height: '28px', width: '56px' }}
            className="bg-gray-300 dark:bg-gray-500 relative mx-2 inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              style={{ height: '24px', width: '24px' }}
              className={`${enabled ? 'translate-x-7' : 'translate-x-0'}
            pointer-events-none inline-block rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
            />
          </Switch>
          <span className="ml-2">Single Token</span>
        </div>

        {enabled && (
          <TokenSearch
            label="Coin/Token Name"
            setId={setHoldingId}
            setName={setTokenName}
            idField="holdingId"
            selection="holdings"
          />
        )}

        <Label name="quantity">Alert Price</Label>
        <TextField
          name="price"
          placeholder="123.45"
          validation={{ required: 'Price is required' }}
        />
        <FieldError name="price" className="block mt-1 text-sm text-red-500" />

        <div className="mt-2 ml-1 ">
          {holdingId ? (
            <PriceAlertLatestPrice holdingId={holdingId} />
          ) : (
            !enabled && <PriceAlertLatestPortfolioPrice />
          )}
        </div>

        <div className="flex justify-between items-center mt-8">
          <Submit className="button-submit" disabled={loading}>
            Add Price Alert
          </Submit>
          <Link to={routes.priceAlerts()} className="button-cancel">
            Cancel
          </Link>
        </div>
      </Form>
      <aside className="w-full md:w-1/3 mt-8">
        <div className="-mt-2 md:ml-12 text-sm leading-6 dark:text-gray-500">
          <p>
            Price alerts will notify you when the price of a given token crosses
            the threshold you set as the{' '}
            <span className="font-semibold dark:text-gray-400">
              Alert Price
            </span>
            . You'll be notified whether the price <em>increases</em> to your
            alert price, or <em>decreases</em> to it.
          </p>
          <p className="mt-4">
            To prevent thrashing, a price alert is disabled after sending.
          </p>
        </div>
      </aside>
    </div>
  )
}

export default NewPriceAlertPage
