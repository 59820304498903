import fetch from 'node-fetch'
import { useState, useEffect } from 'react'
import Autocomplete from 'react-autocomplete'

export const TokenSearch = ({ setId, setName, idField, selection }) => {
  const [selected, setSelected] = useState(false)
  const [value, setValue] = useState('')
  const [data, setData] = useState([])

  useEffect(() => {
    getData()
  }, [])

  const getData = (value = '') => {
    return fetch(
      `${global.__REDWOOD__API_PROXY_PATH}/tokenSearch?q=${value}&selection=${
        selection || 'all'
      }`
    )
      .then((response) => response.json())
      .then((json) => setData(json.tokens))
  }

  const reset = () => {
    setId(null)
    setName('')

    setSelected(false)
    setValue('')
    getData()
  }

  return (
    <div className="relative mb-20">
      <label htmlFor="autocomplete">Coin/Token Name</label>
      <Autocomplete
        id="autocomplete"
        inputProps={{
          id: 'states-autocomplete',
          class: 'text-field',
          placeholder: 'Search...',
        }}
        wrapperProps={{
          class: 'absolute left-0 right-0',
        }}
        value={value}
        items={data}
        getItemValue={(item) => `${item.name} (${item.symbol})`}
        onSelect={(inputValue, item) => {
          setId(idField ? item[idField] : item.id)
          setName(inputValue)

          setSelected(true)
          setValue(inputValue)
          setData([item])
        }}
        onChange={(event, inputValue) => {
          setId(null)
          setName(inputValue)

          setSelected(false)
          setValue(inputValue)
          getData(inputValue)
        }}
        renderMenu={(children) => (
          <div className="bg-gray-700 rounded p-1 shadow-lg max-h-60 overflow-y-scroll">
            {children}
          </div>
        )}
        renderItem={(item, isHighlighted) => (
          <div
            className={`p-2 rounded text-gray-300 cursor-pointer ${
              isHighlighted ? 'bg-gray-800' : ''
            }`}
            key={item.id}
          >
            {item.name} <span className="text-gray-500">({item.symbol})</span>
          </div>
        )}
      />

      {selected && (
        <button type="button" onClick={reset}>
          <span className="absolute top-0 right-0 material-icons md-18 mt-10 mr-4 text-gray-400">
            close
          </span>
        </button>
      )}
    </div>
  )
}

export default TokenSearch
