import { Switch } from '@headlessui/react'
import { useMutation } from '@redwoodjs/web'
import { useAuth } from '@redwoodjs/auth'
import { useEffect, useState } from 'react'

export const USER_MUTATION = gql`
  mutation ThemeMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      theme
    }
  }
`

export const DarkModeSwitch = () => {
  const { currentUser } = useAuth()
  const [enabled, setEnabled] = useState(currentUser.theme === 'dark')
  const [updateUser] = useMutation(USER_MUTATION)

  useEffect(() => {
    const el = document.querySelector('html')
    if (enabled) {
      el.classList.add('dark')
    } else {
      el.classList.remove('dark')
    }
  }, [enabled])

  const onChange = (en) => {
    setEnabled(en)

    updateUser({
      variables: {
        input: { theme: en ? 'dark' : 'light' },
      },
    })
  }

  return (
    <div className="flex items-center justify-center">
      <span className="material-icons md-24 text-gray-400 dark:text-gray-500">
        light_mode
      </span>
      <Switch
        checked={enabled}
        onChange={onChange}
        style={{ height: '28px', width: '56px' }}
        className="bg-gray-300 dark:bg-gray-500 relative mx-2 inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          style={{ height: '24px', width: '24px' }}
          className={`${enabled ? 'translate-x-7' : 'translate-x-0'}
            pointer-events-none inline-block rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
        />
      </Switch>
      <span className="material-icons md-24 text-gray-400 dark:text-gray-500">
        dark_mode
      </span>
    </div>
  )
}

export default DarkModeSwitch
