import { Link, NavLink, routes } from '@redwoodjs/router'
import { useAuth } from '@redwoodjs/auth'
import { Toaster } from '@redwoodjs/web/toast'
import gravatar from 'gravatar'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Menu, Transition } from '@headlessui/react'

import { pageState } from 'src/lib/state'
import DarkModeSwitch from 'src/components/DarkModeSwitch'
import { Fragment } from 'react'

const addAction = (
  <Menu as="div" className="relative inline-block text-left">
    {({ open }) => (
      <>
        <div>
          <Menu.Button className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 whitespace-nowrap">
            + Add
          </Menu.Button>
        </div>
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="absolute right-0 w-36 mt-2 origin-top-right bg-gray-700 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="px-1 py-1">
              <Link to={routes.newAddress()}>
                <Menu.Item>
                  <button className="text-gray-300 hover:text-gray-200 hover:bg-gray-800 group flex rounded-md items-center w-full px-2 py-2 text-sm">
                    Wallet Address
                  </button>
                </Menu.Item>
              </Link>
              <Link to={routes.newHolding()}>
                <Menu.Item>
                  <button className="text-gray-300 hover:text-gray-200 hover:bg-gray-800 group flex rounded-md items-center w-full px-2 py-2 text-sm">
                    Single Token
                  </button>
                </Menu.Item>
              </Link>
              <Link to={routes.newPriceAlert()}>
                <Menu.Item>
                  <button className="text-gray-300 hover:text-gray-200 hover:bg-gray-800 group flex rounded-md items-center w-full px-2 py-2 text-sm">
                    Price Alert
                  </button>
                </Menu.Item>
              </Link>
            </div>
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
)

const AppLayout = ({ children }) => {
  const { currentUser, logOut } = useAuth()
  const [menu, setMenu] = useState(false)
  const page = useRecoilValue(pageState)

  const toggleMenu = (state) => {
    if (state) {
      setMenu(state)
    } else {
      setMenu(!menu)
    }
  }

  const logout = () => {
    toggleMenu(false)
    // turn off dark mode
    document.querySelector('html').classList.remove('dark')
    logOut()
  }

  return (
    <div className="flex items-start md:py-8 md:pr-8 h-full">
      <Toaster />
      <nav
        className={`${
          menu ? 'absolute' : 'hidden'
        } md:block md:relative w-full md:w-1/5 z-10 inset-0 pt-12 md:pt-0 bg-white dark:bg-gray-900 md:bg-transparent px-8 h-full text-center md:text-left text-xl md:text-base`}
      >
        <button
          type="button"
          className="md:hidden"
          onClick={() => toggleMenu(false)}
        >
          <span className="absolute top-0 left-0 mt-6 ml-8 material-icons md-32 text-white text-left">
            close
          </span>
        </button>
        <Link
          to={routes.settings()}
          className="flex items-center justify-center md:justify-start hover:underline dark:text-gray-300"
        >
          <div className="flex-shrink-0 w-12 h-12 rounded-full bg-gray-300">
            <img
              src={gravatar.url(currentUser.email)}
              alt="avatar"
              className="rounded-full"
            />
          </div>
          <h2 className="ml-2">{currentUser.name || 'Anonymous'}</h2>
        </Link>
        <ul className="mt-12 font-semibold text-gray-500 flex-grow">
          <li>
            <NavLink
              onClick={() => toggleMenu(false)}
              to={routes.dashboard()}
              activeClassName="text-blue-700"
            >
              Dashboard
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink
              onClick={() => toggleMenu(false)}
              to={routes.addresses()}
              activeClassName="text-blue-700"
            >
              Addresses
            </NavLink>
          </li>
          <li className="mt-4">
            <NavLink
              onClick={() => toggleMenu(false)}
              to={routes.priceAlerts()}
              activeClassName="text-blue-700"
            >
              Price Alerts
            </NavLink>
          </li>

          <li className="mt-4">
            <button type="button" onClick={logout} className="font-semibold">
              Log Out
            </button>
          </li>
        </ul>
        <div className="mt-12">
          <DarkModeSwitch />
        </div>
      </nav>
      <main className="w-full md:w-4/5">
        <div className="w-full">
          <header className="flex items-center justify-between my-1 min-h-12 px-4 md:px-0 mt-4 md:mt-0">
            <div className="flex items-center">
              <button type="button" onClick={toggleMenu}>
                <span className="block material-icons md-32 dark:text-gray-500 md:hidden">
                  menu
                </span>
              </button>
              <h1 className="ml-2 text-2xl md:text-3xl font-bold dark:text-gray-200">
                {page.title}
              </h1>
            </div>
            {page.actions && (
              <ul>
                <li>{addAction}</li>
                {page.actions.map((action, i) => (
                  <li key={i}>{action}</li>
                ))}
              </ul>
            )}
          </header>
          <section className="mt-4 md:mt-8 bg-white dark:bg-gray-800 transition duration-150 md:rounded-lg shadow py-8 px-6 md:px-8 min-h-128">
            {page.back && (
              <span className="block md:hidden text-xs text-gray-400 -mt-4 mb-2">
                {page.back}
              </span>
            )}
            {page.description && (
              <div className="max-w-screen-md text-gray-900 dark:text-gray-300">
                {page.description}
              </div>
            )}
            {children}
          </section>
        </div>
      </main>
    </div>
  )
}

export default AppLayout
