import { atom, useSetRecoilState } from 'recoil'

export const pageState = atom({
  key: 'pageState',
  default: { title: '', actions: [], pageDescription: null, back: null },
})

export const setPageInfo = (args) => {
  const setPageState = useSetRecoilState(pageState)

  setPageState(args)
}
