import PriceAlertsCell from 'src/components/PriceAlert/PriceAlertsCell'
import { setPageInfo } from 'src/lib/state'

const PriceAlertsPage = () => {
  setPageInfo({
    title: 'Price Alerts',
    actions: [],
    description: null,
  })

  return <PriceAlertsCell />
}

export default PriceAlertsPage
