import { format } from 'date-fns'

export const numberWithDelimiter = (num, options = {}) => {
  const { delimiter = ',', round = 2, prefix = '', fixed = false } = options
  let output = num

  if (round) {
    output = Math.round(num * Math.pow(10, round)) / Math.pow(10, round)
  }

  if (fixed) {
    output = output.toFixed(round)
  }

  const parts = output.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delimiter)

  if (prefix && output < 0) {
    return `-${prefix}${parts.join('.').replace('-', '')}`
  }
  return prefix + parts.join('.')
}

export const numberToCurrency = (num, opts) => {
  return numberWithDelimiter(num, {
    round: 2,
    prefix: '$',
    fixed: true,
    ...opts,
  })
}

export const elasticCurrency = (num) => {
  // if $1 or more, just round to two decimals
  if (num >= 0.1) {
    return numberToCurrency(num, { round: 2, fixed: true })
  }

  // otherwise figure out how many zeros after the decimal and
  // show 3 more significant digits
  const zeros = (num % 1)
    .toFixed(20)
    .toString()
    .match(/\.(0+)/)[1].length

  return numberToCurrency(num, { round: zeros + 3, fixed: true })
}

export const timeFormat = (time, fmt = 'PPPpp') => {
  const date = new Date(time)
  return format(date, fmt)
}

export const toDecimalString = (num) => {
  return ('' + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0)
    }
  )
}

export const autoLink = (text) => {
  const match = text.match(/(https?:\/\/.*?)( |.$)/)
  if (match) {
    return text.replace(
      match[1],
      `<a href="${match[1]}" class="text-blue-500 underline">${match[1]}</a>`
    )
  } else {
    return text
  }
}

export const middleTruncate = (text, max = 25, chars = '......') => {
  if (text.length > max) {
    const length = text.length
    let output = text.substring(0, max / 2 - 3)
    output += chars
    output += text.substring(length - max / 2 + 3, length)
    return output
  } else {
    return text
  }
}
