const GenericLoading = () => {
  return (
    <div className="flex items-center justify-center mt-24 text-gray-300 dark:text-gray-600">
      <span className="material-icons md-28 animate-spin">autorenew</span>
      <span className="ml-2">Loading...</span>
    </div>
  )
}

export default GenericLoading
